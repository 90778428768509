export default {
    created(el, binding, vnode, prevVnode) {},
    beforeMount(el, binding, vnode) {
//        el.addEventListener('click', (val) => {
//            const target = document.querySelector('[collapse-toggle-target=' + binding.value + ']')
//            if (target.classList.contains('hidden')) {
//                target.classList.remove('hidden')
//            } else {
//                target.classList.add('hidden')
//            }
//            console.log(target)
//        })
    },
    mounted(el, binding, vnode) {
        const target = document.querySelector('[collapse-toggle-target=' + binding.value + ']')
        el.addEventListener('click', (val) => {
            if (target.classList.contains('hidden')) {
                target.classList.remove('hidden')

                if (target.tabIndex === -1) {
                    target.tabIndex = 0
                }
                target.focus()
            } else {
                target.classList.add('hidden')
            }
        })
        target.addEventListener('focusout', (val) => {
console.log(target)
            if (!target.contains(document.activeElement)) {
                target.classList.add('hidden')
            }
        })

//        target.getElementsByTagName('*').
        Array.prototype.forEach.call(
            target.getElementsByTagName('*'),
            (e) => {
                console.log(e)
            }
        )
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {}
}

